/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { useLocation, Route, Switch} from "react-router-dom";
import Phonetics from "./Phonetics";
import Home from "./Home";
import Topics from "./Topics";
import Mimics from "./Mimics";
import Similarities from "./Similarities";
import Tools from "./Tools";


const navigation = ['Home', 'Phonetics', 'Topics', 'Mimics', "Similarities", 'Mistakes.js', 'Tools']
const profile = ['Your Profile', 'Settings', 'Sign out']

const navigation1 = [
    { name: 'Home', href: 'home', current: true },
    { name: 'Phonetics', href: 'phonetics', current: false },
    { name: 'Topics', href: 'topics', current: false },
    { name: 'Mimics', href: 'mimics', current: false },
    { name: 'Similarities', href: 'similarities',current: false },
    { name: 'Tools', href: 'tools', current: false },
]

const profile1 = [
    { name: 'Log in', href: '/signin' },
    { name: 'Sign up', href: '#' }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MainPage({ match }) {

    const location = useLocation();

    return (
        <div>
            <Disclosure as="nav" className="bg-indigo-600">
                {({ open }) => (
                    <>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="flex items-center justify-between h-16">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-8 w-8"
                                            src="https://tailwindui.com/img/logos/workflow-mark-indigo-300.svg"
                                            alt="Workflow"
                                        />
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-10 flex items-baseline space-x-4">
                                            {navigation1.map((item, itemIdx) =>
                                                <a
                                                    key={item.name}
                                                    href={`${match.path}/${item.href}`}
                                                    className={classNames(
                                                    location.pathname.indexOf(`${match.path}/${item.href}`) >= 0 ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                                    'px-3 py-2 rounded-md text-sm font-medium'
                                                    )}
                                                >
                                                    {item.name}
                                                </a>
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-4 flex items-center md:ml-6">
                                        <button className="p-1 bg-indigo-600 rounded-full text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                                            <span className="sr-only">View notifications</span>
                                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>

                                        {/* Profile dropdown */}
                                        <Menu as="div" className="ml-3 relative">
                                            {({ open }) => (
                                                <>
                                                    <div>
                                                        <Menu.Button className="max-w-xs bg-indigo-600 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                                                            <span className="sr-only">Open user menu</span>
                                                            <img
                                                                className="h-8 w-8 rounded-full"
                                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                                alt=""
                                                            />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            static
                                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                        >
                                                            {profile.map((item) => (
                                                                <Menu.Item key={item}>
                                                                    {({ active }) => (
                                                                        <a
                                                                            href="#"
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100' : '',
                                                                                'block px-4 py-2 text-sm text-gray-700'
                                                                            )}
                                                                        >
                                                                            {item}
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu.Items>
                                                    </Transition>
                                                </>
                                            )}
                                        </Menu>
                                    </div>
                                </div>
                                <div className="-mr-2 flex md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                {navigation1.map((item, itemIdx) =>
                                    <a
                                        key={item.name}
                                        href={`${match.path}/${item.href}`}
                                        className={classNames(
                                            location.pathname.indexOf(`${match.path}/${item.href}`) >= 0 ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:bg-opacity-75',
                                            'block px-3 py-2 rounded-md text-base font-medium'
                                        )}
                                    >
                                        {item.name}
                                    </a>
                                )}
                            </div>
                            <div className="pt-4 pb-3 border-t border-indigo-700">
                                <div className="flex items-center px-5">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            alt=""
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-white">Tom Cook</div>
                                        <div className="text-sm font-medium text-indigo-300">tom@example.com</div>
                                    </div>
                                    <button className="ml-auto bg-indigo-600 flex-shrink-0 p-1 rounded-full text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
                                        <span className="sr-only">View notifications</span>
                                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mt-3 px-2 space-y-1">
                                    {profile.map((item) => (
                                        <a
                                            key={item}
                                            href="#"
                                            className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                                        >
                                            {item}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>


{/*
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">Home</h1>
                </div>
            </header>
*/}

            <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    {/* Replace with your content
                    <div className="px-4 py-4 sm:px-0">
                        <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
                    </div>
                     /End replace */}

                    <div className="px-4 py-4 sm:px-0">
                        <Switch>
                            <Route path={`${match.path}/home`} exact component={Home}></Route>
                            <Route exact path={`${match.path}/phonetics`} component={Phonetics}></Route>
                            <Route exact path={`${match.path}/topics`} component={Topics}></Route>
                            <Route exact path={`${match.path}/mimics`} component={Mimics}></Route>
                            <Route exact path={`${match.path}/Similarities`} component={Similarities}></Route>
                            <Route exact path={`${match.path}/Tools`} component={Tools}></Route>
                        </Switch>
                    </div>
                </div>
            </main>
        </div>
    )
}

import {Route, Switch} from "react-router-dom";
import Home from "./Home";
import { VolumeUpIcon, PlusCircleIcon } from '@heroicons/react/solid';


const phonetics = [
    {
        name: '[ʌ]',
        role: 'Vowel',
        jaw: 'jaw: drops but the mouth remains neutral',
        tongue: 'tongue: back presses down a little, tip is forward',
        lip: 'lips: a very relaxed jaw drop with relaxed lips',
        example: 'butter [ˈbʌtər]',
        detail: '/vowel-uh',
    },
    {
        name: '[ɑ]',
        role: 'Vowel',
        jaw: 'jaw: a lot of jaw drop',
        tongue: 'tongue: back presses down, tip touches bottom front teeth',
        lip: 'lips: neutral, very relaxed',
        example: 'father /ˈfɑðər/',
        detail: '/vowel-uh',
    },
    // More people...
]

export default function Phonetics() {
    return (
        <div>
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">Phonetics</h1>
                </div>
            </header>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                <div className="px-4 py-4 sm:px-0">
                    {/*<div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />*/}

                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        {phonetics.map((item) => (
                            <li key={item.name} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-3 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex items-center space-x-3">
                                            <h3 className="text-gray-900 text-sm font-medium truncate">{item.name}</h3>
                                            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                              {item.role}
                                            </span>
                                        </div>
                                        <div>
                                            <p className="mt-1 text-gray-500 text-xs">{item.jaw}</p>
                                            <p className="mt-1 text-gray-500 text-xs">{item.tongue}</p>
                                            <p className="mt-1 text-gray-500 text-xs">{item.lip}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                        <div className="w-0 flex-1 flex">
                                            <div
                                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                            >
                                                <VolumeUpIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <span className="ml-3">{item.example}</span>
                                            </div>
                                        </div>
                                        <div className="-ml-px w-0 flex-1 flex">
                                            <a
                                                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                                href={item.detail}
                                            >
                                                <PlusCircleIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <span className="ml-3">Learn More</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

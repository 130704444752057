import React from "react";
import MainPage from "./layout/MainPage";
import SignIn from "./layout/SignIn";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";


const App = () => {
    return (
        <div>
            <BrowserRouter >
                <Switch>
                    <Route path={"/signin"} exact component={ SignIn }></Route>
                    <Route path={"/main"} component={ MainPage }></Route>
                    <Route exact path="/">
                        <Redirect exact from="/" to="main/home" />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App;